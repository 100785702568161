/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import React, { useRef, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import idx from 'idx'
import mapValues from 'lodash/mapValues'
import groupBy from 'lodash/groupBy'
import { theme } from '../../styles'
import Space from '../common/Space'
import CrossIcon from '../../../static/icons/misc-icons/close-icon.svg'
import downChevronGray300 from '../../../static/icons/arrow-icons/chevron-icon-gray300.svg'
import Text from '../common/Text'
import Button from '../common/Button'
import TextLink from '../common/TextLink'
import rightArrowBlack from '../../../static/icons/arrow-icons/right-arrow-icon-black.svg'
import getImage from '../../utils/getImage'

const Wrapper = styled.div`
  padding: 32px;
  background-color: ${theme.color.white};
  border-radius: 8px;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto auto;
  grid-column-gap: 100px;
  grid-row-gap: 32px;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 0;
  height: fit-content;
  width: fit-content;
  min-width: 500px;
  position: absolute;
  transition: 200ms;
  transition-property: transform, opacity, height;
  transform-origin: 50% -50px;

  ${p =>
    p.isSubMenuOpen
      ? `
      z-index: 99999;
      opacity: 1;
      box-shadow: 0 50px 100px 5px rgba(50, 50, 93, 0.25), 0 30px 60px -10px rgba(0, 0, 0, 0.3);
      transform: translateX(0%) rotateX(0deg);
      pointer-events: auto;
  `
      : `
      z-index: -9999;
      opacity: 0;
      transform: translateX(0%) rotateX(-15deg);
      pointer-events: none;
  `};

  @media (max-width: 1200px) {
    position: absolute;
    margin: 16px;
    grid-auto-flow: row;
    grid-template-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 16px;
    min-width: unset;
    padding: 0px;
    transform-origin: 100% 0;
    width: calc(100% - 32px);
    top: 0;
    transform: translateX(0%) rotateX(0deg);

    ${p =>
      p.isSubMenuOpen
        ? `
      transform: scale(1);
  `
        : `
      transform: scale(0.90);
  `};
  }
`

const CardArrow = styled.div`
  position: absolute;
  left: 24px;
  width: 18px;
  height: 18px;
  transform: translateY(-9px) translateX(50%) rotate(45deg);
  border-radius: 3px 0 0 0;
  background-color: ${theme.color.white};
  box-shadow: -3px -3px 4px rgba(145, 140, 151, 0.1);
`

const CategoryWrapper = styled.section`
  grid-row: 1 / 2;

  @media (max-width: 1200px) {
    grid-row: auto;
    grid-column: 1 / 2;
    padding: 0px 24px;
    padding-top: 0px;
  }
`

const SubMenuList = styled.ul`
  transition: all 50ms ease-in-out;
  display: grid;
  grid-template-rows: repeat(8, auto);
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;
  grid-column-gap: 64px;
  padding: 0px;

  &.last-list {
    padding-right: 24px;
  }

  li {
    padding: 8px 0px;
  }

  @media (max-width: 1200px) {
    padding: 8px 24px 12px 0px;
    border-top: 1px solid ${theme.color.offWhite};
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
  }

  .header-text-link a {
    text-decoration: none !important;

    span {
      white-space: nowrap;
    }
    @media (max-width: 800px) {
      span {
        &:hover {
          color: ${theme.color.offBlack}!important;
        }
      }
    }
  }
`

const CloseButton = styled.button`
  padding: 4px;
  background: none;
  align-self: flex-end;
`

const CloseIcon = styled.img`
  width: 14px;
`

const HeaderListItem = styled.li`
  position: relative;
  margin-bottom: 0px;
  display: flex;

  a {
    ::before {
      opacity: 0;
      transition: all 100ms ease-in-out;
      content: '';
      position: absolute;
      background: url(${rightArrowBlack});
      background-position: center;
      background-repeat: no-repeat;
      top: 0;
      bottom: 0;
      right: 0;
      left: 100%;
      width: 20px;
      margin-top: 1px;
      margin-left: 4px;
    }

    :hover {
      ::before {
        opacity: 1;
        margin-left: 12px;
      }
    }
    :focus {
      ::before {
        opacity: 1;
        margin-left: 12px;
      }
    }
  }
  @media (max-width: 1200px) {
    margin-bottom: 0px;
  }
`

const HeaderTextLink = styled(TextLink)`
  text-decoration: none !important;
  font-family: ${theme.fonts.family.bodyHeavy};
`

const LinkIcon = styled.img`
  margin-right: 16px;
  width: 18px;
  height: auto;
`

const CtaWrapper = styled.section`
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  padding: 24px 48px 24px 32px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: ${theme.color.purple200};

  @media (max-width: 1200px) {
    grid-row: auto;
    grid-column: 1 / 2;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    margin: 0px 24px;
    margin-bottom: 16px;
  }

  .button {
    margin-right: 26px;

    :before {
      margin-top: 3px;
    }
  }
`

const MobFooterWrapper = styled.section`
  border-radius: 0px 0px 8px 8px;
  padding: 24px;
  padding-top: 16px;
  display: grid;
  gap: 16px;
  background-color: ${theme.color.offWhite};

  a {
    text-decoration: none !important;
  }
`

const MobSubMenuHeaderWrapper = styled.section`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 24px;
`

const SubMenuHeaderButton = styled.button`
  background-color: transparent;
  padding: 0px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${downChevronGray300});
    background-size: 14px;
    width: 14px;
    height: 14px;
    margin-right: 12px;
    margin-left: -3px;
    transform: rotate(90deg);
  }
`

const CategoryHeader = ({ categoryHeader }) =>
  categoryHeader ? (
    <Text
      smallBodyHeavy
      color="gray300"
      style={{ textTransform: 'uppercase', letterSpacing: '.02rem', marginBottom: '16px' }}
      id={categoryHeader}
    >
      {categoryHeader?.charAt(0).toUpperCase() + categoryHeader?.slice(1)}
    </Text>
  ) : null

const Cta = ({ categoryName, isSubMenuOpen, toggleOpen }) => {
  let ctaContent = ''
  switch (categoryName) {
    case 'product':
      ctaContent = {
        text: 'Want to see AudioEye in action?',
        buttonText: 'Schedule a demo',
        url: '/free-consultation',
      }
      break
    case 'compliance':
      ctaContent = {
        text: 'Received a demand letter?',
        buttonText: 'Request legal support',
        url: '/request-legal-support-services',
      }
      break
    case 'partners':
      ctaContent = {
        text: 'Have client websites?',
        buttonText: 'Become a partner',
        url: '/become-a-partner',
      }
      break
    case 'company':
      ctaContent = {
        text: 'Ready to connect?',
        buttonText: 'Contact us',
        url: '/contact-us',
      }
      break
    default:
      ctaContent = null
  }

  return (
    <CtaWrapper>
      <Text smallBodyHeavy>{ctaContent?.text}</Text>
      <Space width={32} mobileWidth={0} mobileHeight={8} />
      <Button
        buttonType="link CTA"
        text={ctaContent?.buttonText}
        to={ctaContent?.url}
        tabIndex={isSubMenuOpen ? 0 : -1}
        style={{ padding: '3px 3px' }}
        className="submenu-cta"
        onKeyDown={e => {
          if (e.key === 'Tab') toggleOpen()
        }}
      />
    </CtaWrapper>
  )
}

const MobSubMenuHeader = ({ category, setIsOpen, toggleOpen }) => (
  <MobSubMenuHeaderWrapper>
    <SubMenuHeaderButton onClick={() => toggleOpen()}>
      {category?.charAt(0) && <Text bodyHeavy>{category?.charAt(0).toUpperCase() + category?.slice(1)}</Text>}
    </SubMenuHeaderButton>
    <CloseButton
      role="button"
      aria-label="Close menu"
      onClick={() => setIsOpen(false)}
      onKeyDown={e => {
        if (e.key === 'Enter') setIsOpen(false)
      }}
    >
      <CloseIcon alt="" src={CrossIcon} />
    </CloseButton>
  </MobSubMenuHeaderWrapper>
)

const SubMenu = ({
  textLinks,
  isSubMenuOpen,
  toggleOpen,
  firstListFirstItemRef,
  mobile,
  secondaryCTAText,
  secondaryCTALink,
  showCTA,
  setIsOpen,
}) => {
  const [categoryName, setCategoryName] = useState()
  const categoryListRef = useRef(null)
  const subListItemRefs = useRef([])
  const secondListFirstItemRef = useRef(null)
  const firstListLastItemRef = useRef(null)
  const secondListLastItemRef = useRef(null)

  const outsideClickListener = event => {
    if (!event.target.closest('.submenu-wrapper') && isSubMenuOpen) toggleOpen(true)
  }

  const handleRef = (el, index, sectionIndex, listLength) => {
    if (index === 0 && sectionIndex === 0) {
      firstListFirstItemRef.current = el?.querySelector('a')
    }
    if (index === 0 && sectionIndex + 1 === listLength) {
      firstListLastItemRef.current = el?.querySelector('a')
    }
    if (index > 0 && sectionIndex === 0) {
      secondListFirstItemRef.current = el?.querySelector('a')
    }
    if (index > 0 && sectionIndex + 1 === listLength) {
      secondListLastItemRef.current = el?.querySelector('a')
    }
  }

  useEffect(() => {
    if (!mobile) {
      const htmlEl = document.querySelector('html')
      setTimeout(() => {
        isSubMenuOpen ? htmlEl?.classList.add('no-scroll') : htmlEl?.classList.remove('no-scroll')
      }, 25)
    }
  }, [isSubMenuOpen])

  useEffect(() => {
    if (isSubMenuOpen) {
      setTimeout(() => {
        document.addEventListener('click', outsideClickListener)
      }, 25)
    }
    return function cleanup() {
      document.removeEventListener('click', outsideClickListener)
    }
  }, [isSubMenuOpen])

  const submenuCategoryLinks = mapValues(groupBy(textLinks, 'category'))
  const submenuArr = Object.entries(submenuCategoryLinks)

  useEffect(() => {
    submenuArr.map(category => setCategoryName(category[0].split('-')[0]))
  }, [categoryName])

  return (
    <Wrapper
      isSubMenuOpen={isSubMenuOpen}
      onKeyDown={e => {
        if (e.key === 'Escape') toggleOpen()
      }}
      ref={categoryListRef}
      className={`${categoryName} submenu-wrapper`}
      id={`${categoryName}-submenu`}
      hidden={!isSubMenuOpen}
      aria-labelledby={`${categoryName}-button`}
    >
      {!mobile && <CardArrow />}
      {mobile && <MobSubMenuHeader category={categoryName} setIsOpen={setIsOpen} toggleOpen={toggleOpen} />}
      {submenuArr.map((category, index) => (
        <CategoryWrapper key={`${category[1][0]?.top_level_title}-${index}`}>
          <CategoryHeader categoryHeader={category[0].split('-')[1]} />
          <SubMenuList
            aria-labelledby={category[0].split('-')[1]}
            isSubMenuOpen={isSubMenuOpen}
            className={`${category[0].split('-')[1]} ${index > 0 ? 'last-list' : ''}`}
            ref={element => {
              subListItemRefs.current[index] = element
            }}
          >
            {category[1].map((textLink, sectionIndex) => {
              const subMenuLink = idx(textLink, _ => _.url.url) || idx(textLink, _ => _.fallback_url)
              const isOutBoundLink = subMenuLink.includes('http')
              const listLength = category[1].length
              const firstListFirstItem = index === 0 && sectionIndex === 0
              const firstListLastItem = index === 0 && sectionIndex + 1 === listLength
              const secondListFirstItem = index > 0 && sectionIndex === 0
              const secondListLastItem = index > 0 && sectionIndex + 1 === listLength

              return (
                <React.Fragment key={`${textLink?.top_level_title}-${index}-${sectionIndex}`}>
                  <HeaderListItem
                    className="header-text-link"
                    id={textLink?.top_level_title.toString().replace(/ /g, '-').toLowerCase()}
                    ref={el => handleRef(el, index, sectionIndex, listLength)}
                    onKeyDown={e => {
                      if (e.key === 'Escape') toggleOpen()
                    }}
                  >
                    {getImage(textLink.icon, LinkIcon)}
                    <HeaderTextLink
                      font="smallBody"
                      color="offBlack"
                      text={textLink?.top_level_title}
                      to={subMenuLink}
                      tabIndex={isSubMenuOpen ? 0 : -1}
                      data-cy={idx(textLink, _ => _.top_level_title)
                        .toString()
                        .replace(/ /g, '-')
                        .toLowerCase()}
                      onKeyDown={e => {
                        if (e.key === 'Tab' && e.shiftKey && firstListFirstItem) toggleOpen(true, true)
                        if (e.key === 'ArrowUp') {
                          if (e.target?.parentElement?.previousElementSibling?.children) {
                            e.target.parentElement.previousElementSibling.children?.[1].focus()
                          }
                          if (firstListFirstItem) toggleOpen(true)
                          if (secondListFirstItem) firstListLastItemRef?.current.focus()
                        }
                        if (e.key === 'ArrowDown') {
                          if (
                            !firstListLastItem &&
                            !secondListLastItem &&
                            e.target?.parentElement?.nextElementSibling?.children
                          ) {
                            e.target.parentElement.nextElementSibling.children?.[1].focus()
                          }
                          if (firstListLastItem && secondListFirstItemRef?.current) {
                            secondListFirstItemRef?.current.focus()
                          }
                          if (firstListLastItem && !secondListFirstItemRef?.current) toggleOpen(true)
                          if (secondListLastItem) toggleOpen(true)
                        }
                      }}
                      outboundHeaderLink={isOutBoundLink}
                    />
                  </HeaderListItem>
                </React.Fragment>
              )
            })}
          </SubMenuList>
        </CategoryWrapper>
      ))}
      <Cta categoryName={categoryName} isSubMenuOpen={isSubMenuOpen} toggleOpen={toggleOpen} />
      {mobile && (
        <MobFooterWrapper>
          <HeaderTextLink
            text="Login"
            to={process.env.GATSBY_AEYE_PORTAL_LOGIN_URL}
            font="smallBodyHeavy"
            color="offBlack"
          />
          {showCTA && (
            <Button
              text="Start Free Trial"
              aria-label="Start Free Trial"
              size="small"
              onClick={e => {
                e.preventDefault()
                setIsOpen(false)
                navigate('#get-started-free')
              }}
            />
          )}
          {secondaryCTAText && secondaryCTALink && (
            <Button text={secondaryCTAText} to={secondaryCTALink} size="small" buttonType="secondary" />
          )}
        </MobFooterWrapper>
      )}
    </Wrapper>
  )
}

export default SubMenu
